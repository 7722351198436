import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
    Layout,
    Section,
    Caption,
    Hero,
    FeaturedProjects,
} from '../components/common';
import { StaticRequestAQuoteButton } from '../components/common/RequestQuote';
import SimpleStaticServices from '../components/common/SimpleStaticServices';
import AltHeroText from '../components/common/AltHeroText';
import { StaticLocales } from '../components/common/Locales';
import { parseFeaturedProjects } from '../util/parse';
import PrismicImage from '../models/prismicImage';
import { withPreviewData } from '../util/preview';
import RichContentSection from '../components/common/RichContentSection';

const useStyles = makeStyles((theme) => ({
    costSection: {
        textAlign: 'left',
    },
    section: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    intro: {
        marginBottom: theme.spacing(2),
        textAlign: 'left',
    },
    introText: {
        marginBottom: theme.spacing(4),
        textAlign: 'left',
        fontSize: 28,
    },
    caption: {
        '& p': {
            margin: '1em 0',
        },
    },
}));

const ProjectType = ({ data: staticData, location }) => {
    const data = withPreviewData(staticData);
    const {
        title,
        description,
        json_ld: jsonLD,
        mainImage,
        heroTitle,
        heroCaption,
        textBody,
        costParagraph,
        featured_projects_title: featuredProjectsTitle,
        featuredProjectsSubtitle,
        featured_projects: featuredProjects,
        typesOfProjectsTitle,
        content_block_1: contentBlock1,
        content_block_2: contentBlock2,
    } = data.prismicProjectType.data;

    const classes = useStyles();
    return (
        <Layout
            location={location}
            customMetaData={{
                title: title.text,
                description: description.text,
                jsonLD: (jsonLD || {}).text,
            }}
        >
            <Hero image={new PrismicImage(mainImage)}>
                <AltHeroText
                    title={heroTitle.text}
                    subtitle={heroCaption.text}
                />
            </Hero>
            <Section classes={{ root: classes.section }}>
                <Typography variant="h2" classes={{ root: classes.introText }}>
                    {textBody.text}
                </Typography>
                <StaticRequestAQuoteButton
                    contextDescription="service-description"
                    size="large"
                />
            </Section>
            {contentBlock1 && contentBlock1.html ? (
                <RichContentSection html={contentBlock1.html} />
            ) : undefined}
            <FeaturedProjects
                title={featuredProjectsTitle.text}
                subtitle={featuredProjectsSubtitle.text}
                projects={parseFeaturedProjects(featuredProjects)}
                rows={6}
            />
            <StaticLocales />
            {contentBlock2 && contentBlock2.html ? (
                <RichContentSection html={contentBlock2.html} />
            ) : undefined}
            <SimpleStaticServices title={typesOfProjectsTitle.text} />
            {costParagraph && costParagraph.html ? (
                <Section classes={{ root: classes.costSection }}>
                    <Caption
                        classes={{ root: classes.caption }}
                        caption={costParagraph.html}
                    />
                </Section>
            ) : undefined}
        </Layout>
    );
};

export default ProjectType;

ProjectType.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export const query = graphql`
    query ProjectTypeQuery($uid: String!) {
        prismicProjectType(uid: { eq: $uid }) {
            data {
                title {
                    text
                }
                description {
                    text
                }
                json_ld {
                    text
                }
                mainImage {
                    ...ProPrismicImageMetadata_NoBase64
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                heroTitle {
                    text
                }
                heroCaption {
                    text
                }
                textBody {
                    text
                }
                costParagraph {
                    html
                }
                featured_projects_title {
                    text
                }
                featuredProjectsSubtitle {
                    text
                }
                featured_projects {
                    image {
                        ...ProPrismicImageMetadata_NoBase64
                        localFile {
                            childImageSharp {
                                fixed(width: 400) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                    project_title {
                        text
                    }
                    project_description {
                        text
                    }
                    project_link {
                        url
                        type
                    }
                }
                typesOfProjectsTitle {
                    text
                }
                content_block_1 {
                    html
                }
                content_block_2 {
                    html
                }
            }
        }
    }
`;
