import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Hidden } from '@material-ui/core';

import Section from './Section';
import SectionHeader from './SectionHeader';
import Link from './LinkWrapper';

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        margin: 0,
    },
    imageOverlay: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(4, 2),
        boxShadow:
            '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -1px rgba(0, 0, 0, 0.12)',
        '&:hover': {
            boxShadow: theme.shadows[4],
        },
    },
    serviceTypeText: {
        fontWeight: 600,
        fontSize: 22,
    },
    container: {
        marginTop: theme.spacing(1),
    },
}));

function SimpleServices(props) {
    const { title, projectTypes, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <Section color="secondary" className={classes.root}>
            <SectionHeader title={title} />
            <Grid container spacing={8} className={classes.container}>
                {projectTypes.map((item, i) => (
                    <React.Fragment key={`${i + 1}`}>
                        {i % 2 === 0 ? (
                            <Hidden mdDown>
                                <Grid item sm={1} />
                            </Hidden>
                        ) : undefined}
                        <Grid item xs={12} sm={6} lg={5}>
                            <Link to={item.link.url}>
                                <div className={classes.imageOverlay}>
                                    <Typography
                                        variant="button"
                                        className={classes.serviceTypeText}
                                    >
                                        {item.title.text}
                                    </Typography>
                                </div>
                            </Link>
                        </Grid>
                        {(i + 1) % 2 === 0 ? (
                            <Hidden mdDown>
                                <Grid item sm={1} />
                            </Hidden>
                        ) : undefined}
                    </React.Fragment>
                ))}
            </Grid>
        </Section>
    );
}

SimpleServices.propTypes = {
    title: PropTypes.string,
    projectTypes: PropTypes.arrayOf(PropTypes.object),
};

SimpleServices.defaultProps = {
    title: '',
    projectTypes: [],
};

export default SimpleServices;
