import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Carousel, { Modal, ModalGateway } from 'react-images';
import PrismicImage from '../../models/prismicImage';

function Lightbox({ open, setOpen, images, startIndex }) {
    const theme = useTheme();
    const zIndexStyle = (otherStyles) => ({
        ...otherStyles,
        zIndex: theme.zIndex.appBar + 100,
    });

    const views = images
        .map((image) => {
            const fluid = image.fluid({ maxWidth: 1920 });

            const view = {
                src: fluid.src,
                alt: image.alt,
            };

            return view.src ? view : undefined;
        })
        .filter((view) => Boolean(view));

    return (
        <ModalGateway>
            {open ? (
                <Modal
                    onClose={() => setOpen(false)}
                    styles={{
                        blanket: zIndexStyle,
                        positioner: zIndexStyle,
                        dialog: (otherStyles) => ({
                            ...otherStyles,
                            width: '100%',
                        }),
                    }}
                >
                    <Carousel views={views} currentIndex={startIndex} />
                </Modal>
            ) : undefined}
        </ModalGateway>
    );
}

Lightbox.propTypes = {
    images: PropTypes.arrayOf(PropTypes.instanceOf(PrismicImage)),
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    startIndex: PropTypes.number,
};

Lightbox.defaultProps = {
    images: [],
    startIndex: 0,
};

export default Lightbox;
