import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FluidImage } from '../common/Image';

const imageStylesOverride = {
    objectFit: 'cover',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
};

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(8, 0, 0),
    },
    imageParent: {
        height: '100%',
    },
}));

const ImageGrid = ({ images, onClick, ...otherProps }) => {
    const classes = useStyles(otherProps.classes);

    if (!images.length) return <></>;

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                {images.map((item, i) => (
                    <Grid
                        item
                        key={`image_${i + 1}`}
                        xs={item.isFeatured === 'featured' ? 12 : 6}
                    >
                        <div
                            className={classes.imageParent}
                            role="button"
                            tabIndex={i}
                            onKeyDown={(e) => e.key === 'Enter' && onClick(i)}
                            onClick={() => onClick(i)}
                        >
                            <FluidImage
                                image={item.image}
                                style={imageStylesOverride}
                                maxWidth={
                                    item.isFeatured === 'featured' ? 1280 : 640
                                }
                            />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

ImageGrid.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            isFeatured: PropTypes.string.isRequired,
            image: PropTypes.object.isRequired,
        })
    ).isRequired,
    onClick: PropTypes.func,
    classes: PropTypes.object,
};

ImageGrid.defaultProps = {
    classes: undefined,
    onClick: () => {},
};

export default ImageGrid;
