import React from 'react';
import { Helmet } from 'react-helmet';

import { withPreviewData } from '../util/preview';
import LoadingMessage from '../components/preview/LoadingMessage';

import CityPage from '../templates/city';
import ProjectPage from '../templates/project';
import ProjectTypePage from '../templates/projectType';
import TermsPage from '../templates/terms';

/**
 * All templates in `src/templates` should be in this map
 * in order to render unpublished pages.
 *
 * NOTE: there's no need to include templates from `src/pages`
 * since those can only be instantiated once and therefore
 * shouldn't have unpublished instances.
 */
const docTypeToTemplate = {
    city: CityPage,
    project: ProjectPage,
    'project-type': ProjectTypePage,
    terms: TermsPage,
};

function UnpublishedPage(props) {
    const data = withPreviewData();

    let PageTemplate;
    let type;
    if (data) {
        const prismicPageName = Object.keys(data)[0];
        ({ type } = data[prismicPageName]);

        PageTemplate = docTypeToTemplate[type];
    }

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            {PageTemplate ? (
                <PageTemplate {...props} data={data} />
            ) : (
                <LoadingMessage>
                    Unknown page type, &quot;{type}&quot;. Unable to render
                    preview.
                </LoadingMessage>
            )}
        </>
    );
}

export default UnpublishedPage;
