import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import SimpleServices from './SimpleServices';
import { withPreviewData } from '../../util/preview';

const query = graphql`
    query SimpleServicesQuery {
        prismicFeaturedProjectTypes {
            data {
                default_project_types_title {
                    text
                }
                project_types {
                    link {
                        url
                        type
                    }
                    title {
                        text
                    }
                }
            }
        }
    }
`;

function StaticServices({ title }) {
    return (
        <StaticQuery
            query={query}
            render={(staticData) => {
                const {
                    prismicFeaturedProjectTypes: {
                        data: {
                            default_project_types_title: defaultTitle,
                            project_types: projectTypes,
                        },
                    },
                } = withPreviewData(staticData);

                return (
                    <SimpleServices
                        title={title || defaultTitle.text}
                        projectTypes={projectTypes}
                    />
                );
            }}
        />
    );
}

StaticServices.propTypes = {
    title: PropTypes.string,
};

StaticServices.defaultProps = {
    title: undefined,
};

export default StaticServices;
