import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { Transition } from 'react-transition-group';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import blue from '@serviceslabs/material-ui-pro/colors/blue';

import Caption from '../Caption';
import Section from '../Section';

import desktopMapImg from '../../../images/us-map-desktop.png';
import mobileMapImg from '../../../images/us-map-mobile.png';
import mapPin from '../../../images/map-pin.svg';

/**
 * We've traced out an accurate U.S. map and can place pins with pixel offsets
 * based on the latitude and longitude of a location. To do that, we'll
 * use these reference points of the absolute top, right, bottom, and left
 * of lat/longs of the map.
 */
const northernMostLat = 49.384472;
const southernMostLat = 25.118333;
const easternMostLong = -66.949778;
const westernMostLong = -124.733056;

// Calculate the length and height of the map as lat/long differences
const longWidth = Math.abs(easternMostLong - westernMostLong);
const latHeight = Math.abs(northernMostLat - southernMostLat);

// Configure the known height and width of the image
const imageWidth = 600;
const imageHeight = 316;

// Given the pixel size of the map and the lat/long dimensions, figure out
// the ratio between lat/long coords and pixels
const pixelsPerLong = imageWidth / longWidth;
const pixelsPerLat = imageHeight / latHeight;

// Configure some offsets so the map isn't flush with the top-left
// of the container. These will be used in the css styles as well as
// when calculating the pixel offsets of the pins
const desktopOffset = { x: 18, y: 42 };
const mobileOffset = { x: 36, y: 292 };

// Configure offsets to add so the tip of the pin lands where we want it
// rather than positioning the top-left of the pin image
const pinOffset = { x: 18, y: 50 };

const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
        position: 'relative',
        borderRadius: 6,
        backgroundColor: blue[300],
        backgroundRepeat: 'no-repeat',
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(3),
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url('${mobileMapImg}')`,
            height: 580,
            backgroundPosition: `${mobileOffset.x}px ${mobileOffset.y}px`,
            flexDirection: 'column-reverse',
        },
        [theme.breakpoints.up('sm')]: {
            backgroundImage: `url('${desktopMapImg}')`,
            height: 278,
            backgroundPosition: `${desktopOffset.x}px ${desktopOffset.y}px`,
        },
    },
    pin: {
        position: 'absolute',
    },
    textContainer: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '52%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '60%',
        },
    },
    title: {
        fontFamily: 'Gibson',
        fontWeight: 600,
        lineHeight: 1.2,
        [theme.breakpoints.down('md')]: {
            fontSize: 32,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 36,
        },
    },
    text: {
        paddingTop: theme.spacing(1),
        fontFamily: 'Gibson',
        lineHeight: 1.35,
        '& a': {
            color: 'inherit',
        },
        '& p': {
            margin: 0,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: 22,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 28,
        },
    },
}));

/**
 * Given a latitude and longitude calculate the pixel offsets for the pins
 * both for desktop and mobile (since the styling calls for the map to be
 * padded differently within the container on desktop and mobile)
 *
 * @param {number} lat
 * @param {number} long
 */
export function calcOffset(lat, long) {
    const coords = {
        x: Math.abs(long - westernMostLong) * pixelsPerLong - pinOffset.x,
        y: Math.abs(northernMostLat - lat) * pixelsPerLat - pinOffset.y,
    };

    return {
        desktop: {
            x: coords.x + desktopOffset.x,
            y: coords.y + desktopOffset.y,
        },
        mobile: {
            x: coords.x + mobileOffset.x,
            y: coords.y + mobileOffset.y,
        },
    };
}

/** pinDropHeight - How far in pixels to drop the pin */
const pinDropHeight = 50;
/** pinDropDuration - How long it takes in milliseconds to drop */
const pinDropDuration = 200;
/** pinDropDelay - The delay between each pin drop animation */
const pinDropDelay = 200;

function Locales({ title, text, pins, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });
    const theme = useTheme();
    const pinCoordType = useMediaQuery(theme.breakpoints.down('xs'))
        ? 'mobile'
        : 'desktop';
    const [ref, inView] = useInView({ threshold: 0.5, triggerOnce: true });

    const pinsWithOffsets = pins.reduce((newPins, pin) => {
        const { latitude, longitude } = pin;
        if (latitude && longitude) {
            newPins.push({ ...pin, ...calcOffset(latitude, longitude) });
        }
        return newPins;
    }, []);

    return (
        <Section classes={{ root: classes.root }}>
            <div className={classes.container} ref={ref}>
                {pinsWithOffsets.map(
                    ({ [pinCoordType]: { x, y }, cityName: pinTitle }, i) => {
                        const defaultStyle = {
                            transition: `top ${pinDropDuration}ms ease-in, opacity ${pinDropDuration}ms ease-in`,
                            opacity: 0,
                            left: `${x}px`,
                            top: `${y}px`,
                        };
                        const transitionStyles = {
                            entering: {
                                top: `${y - pinDropHeight}px`,
                                opacity: 0,
                            },
                            entered: {
                                top: `${y}px`,
                                opacity: 1,
                            },
                            exiting: {
                                top: `${y}px`,
                                opacity: 1,
                            },
                            exited: {
                                top: `${y - pinDropHeight}px`,
                                opacity: 0,
                            },
                        };

                        return (
                            <Transition
                                key={pinTitle}
                                in={inView}
                                timeout={{
                                    enter: (i + 1) * pinDropDelay,
                                    exit: 0,
                                }}
                            >
                                {(state) => {
                                    return (
                                        <img
                                            className={classes.pin}
                                            alt={`${pinTitle} map pin`}
                                            title={pinTitle}
                                            src={mapPin}
                                            style={{
                                                ...defaultStyle,
                                                ...transitionStyles[state],
                                            }}
                                        />
                                    );
                                }}
                            </Transition>
                        );
                    }
                )}
                <div className={classes.textContainer}>
                    <Typography variant="h2" className={classes.title}>
                        {title}
                    </Typography>
                    <Caption classes={{ root: classes.text }} caption={text} />
                </div>
            </div>
        </Section>
    );
}

Locales.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    pins: PropTypes.arrayOf(
        PropTypes.shape({
            cityName: PropTypes.string.isRequired,
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        })
    ).isRequired,
};

export default Locales;
