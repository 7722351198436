import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import Section from './Section';
import { RichText } from './prismic';

const useStyles = makeStyles(() => ({
    root: {},
    typography: {
        textAlign: 'left',
    },
    richText: {
        '& p': {
            '&:not(:first-child)': {
                marginTop: '1em',
            },
            '&:not(:last-child)': {
                marginBottom: '1em',
            },
        },
    },
}));

function RichContentSection({ html, ...otherProps }) {
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <Section classes={{ root: classes.root }}>
            <Typography className={classes.typography} component="div">
                <RichText html={html} classes={{ root: classes.richText }} />
            </Typography>
        </Section>
    );
}

RichContentSection.propTypes = {
    html: PropTypes.string.isRequired,
};

RichContentSection.defaultProps = {};

export default RichContentSection;
