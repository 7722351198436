import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { Section } from '../common';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    container: {
        textAlign: 'left',
    },
    item: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3, 0),
            '&:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
            },
        },
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
            display: 'flex',
            borderLeft: `1px solid ${theme.palette.grey[400]}`,
            '&:last-child': {
                paddingRight: 0,
            },
        },
    },
    itemTitle: {
        textTransform: 'uppercase',
    },
    textContainer: {},
}));

function InfoBar(props) {
    const { items: unfilteredItems, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    const items = unfilteredItems.filter(
        ({ title, body }) => !!(title || body)
    );

    if (!items.length) return <></>;

    return (
        <Section color="secondary" classes={{ root: classes.root }}>
            <Grid container className={classes.container} justify="flex-start">
                {items.map(({ title, body }, index) => (
                    <Grid
                        item
                        className={classes.item}
                        key={`item_${index + 1}`}
                        sm={4}
                        xs={12}
                    >
                        <div className={classes.textContainer}>
                            <Typography
                                variant="h4"
                                className={classes.itemTitle}
                            >
                                {title}
                            </Typography>
                            <Typography variant="body1">{body}</Typography>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
}

InfoBar.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
};

InfoBar.defaultProps = {
    classes: undefined,
    items: [],
};

export default InfoBar;
