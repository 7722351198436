import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import RichContentSection from '../common/RichContentSection';

const KUULA_BASE_URL = `https://kuula.co/share`;
const KUULA_URL_OPTS = `fs=1&vr=0&initload=0&thumbs=1&chromeless=1&logo=-1`;
const IFRAME_TITLE = '3D project rendering';

const defaultIframeProps = {
    width: '100%',
    height: '640',
    style: {
        width: '100%',
        border: 'none',
        maxWidth: '100%',
        marginBottom: -5,
    },
    frameBorder: '0',
    allowFullscreen: 'true',
    allow: 'gyroscope,accelerometer,fullscreen',
    scrolling: 'no',
};

function Kuula3dRender({ id, description, ...otherProps }) {
    const height = useMediaQuery(useTheme().breakpoints.down('sm')) ? 640 : 800;

    if (!id.length) return <></>;

    const src = `${KUULA_BASE_URL}/${id}?${KUULA_URL_OPTS}`;

    const iframeProps = {
        ...defaultIframeProps,
        src,
        style: { ...defaultIframeProps.style, height },
    };

    return (
        <span {...otherProps}>
            {!!description.text && (
                <RichContentSection html={description.html} />
            )}
            <iframe title={IFRAME_TITLE} {...iframeProps} />
        </span>
    );
}

Kuula3dRender.propTypes = {
    id: PropTypes.string,
    description: PropTypes.object,
};

Kuula3dRender.defaultProps = {
    id: '',
    description: {},
};

export default Kuula3dRender;
