import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/styles';

import {
    Layout,
    FeaturedProjects,
    Team,
    Hero,
    VideoTestimonials,
    QuoteTestimonials,
    StaticValueCallouts,
    AltHeroText,
    SectionText,
} from '../components/common';
import SimpleStaticServices from '../components/common/SimpleStaticServices';

import {
    parseTeamRow,
    parseCityTestimonials,
    parseSeeMore,
    parseFeaturedProjects,
} from '../util/parse';
import { withPreviewData } from '../util/preview';
import PrismicImage from '../models/prismicImage';
import RichContentSection from '../components/common/RichContentSection';

const useStyles = makeStyles(() => ({
    team: {
        paddingBottom: 0,
    },
    testimonials: {
        paddingTop: 0,
    },
}));

function City({ data: staticData, location }) {
    const data = withPreviewData(staticData);

    const {
        // metadata
        title,
        description,
        json_ld: jsonLD,
        // hero
        heroImage,
        heroTitle,
        heroBody,
        // location
        whereWeWork,
        mapIcon,
        locationBody,
        // seo content blocks
        content_block_1: contentBlock1,
        content_block_2: contentBlock2,
        // team row
        teamRow,
        teamRowTitle,
        teamRowSubtitle,
        // featured
        featured_projects_title: featuredProjectsTitle,
        featuredProjectsSubtitle,
        featured_projects: featuredProjects,
        seeMoreText,
        seeMoreLink,
        // testimonials
        testimonialsTitle,
        testimonials,
        videos,
    } = data.prismicCity.data;

    const classes = useStyles();
    const teamMembers = parseTeamRow(teamRow);

    return (
        <Layout
            location={location}
            customMetaData={{
                title: title.text,
                description: description.text,
                jsonLD: (jsonLD || {}).text,
            }}
        >
            <Hero image={new PrismicImage(heroImage)}>
                <AltHeroText title={heroTitle.text} subtitle={heroBody.text} />
            </Hero>
            <SectionText
                title={whereWeWork.text}
                body={locationBody.html}
                mapImage={new PrismicImage(mapIcon)}
            />
            {contentBlock1 && contentBlock1.html ? (
                <RichContentSection html={contentBlock1.html} />
            ) : undefined}
            {featuredProjects.length ? (
                <FeaturedProjects
                    color="default"
                    title={featuredProjectsTitle.text}
                    subtitle={featuredProjectsSubtitle.text}
                    projects={parseFeaturedProjects(featuredProjects)}
                    seeMore={parseSeeMore(seeMoreText, seeMoreLink)}
                    rows={6}
                />
            ) : undefined}
            {contentBlock2 && contentBlock2.html ? (
                <RichContentSection html={contentBlock2.html} />
            ) : undefined}
            <SimpleStaticServices />
            {teamRowTitle.text && teamMembers && teamMembers.length ? (
                <Team
                    title={teamRowTitle.text}
                    subtitle={teamRowSubtitle.text}
                    items={teamMembers}
                    classes={{ root: classes.team }}
                />
            ) : undefined}
            <VideoTestimonials title={testimonialsTitle.text} videos={videos} />
            <QuoteTestimonials
                testimonials={parseCityTestimonials(testimonials)}
                classes={{ root: classes.testimonials }}
            />
            <StaticValueCallouts />
        </Layout>
    );
}
export default City;

City.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export const query = graphql`
    query CityQuery($uid: String!) {
        prismicCity(uid: { eq: $uid }) {
            data {
                title {
                    text
                }
                description {
                    text
                }
                json_ld {
                    text
                }
                heroImage {
                    ...ProPrismicImageMetadata_NoBase64
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                heroTitle {
                    text
                }
                heroBody {
                    text
                }
                locationBody {
                    html
                }
                whereWeWork {
                    text
                }
                mapIcon {
                    ...ProPrismicImageMetadata_NoBase64
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 960) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                teamRowSubtitle {
                    text
                }
                teamRowTitle {
                    text
                }
                teamRow {
                    text {
                        html
                    }
                    name {
                        text
                    }
                    team_member_title
                    icon {
                        ...ProPrismicImageMetadata_NoBase64
                        localFile {
                            childImageSharp {
                                fixed(width: 140, height: 140) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                }
                featured_projects {
                    image {
                        ...ProPrismicImageMetadata_NoBase64
                        localFile {
                            childImageSharp {
                                fixed(width: 400) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                    project_title {
                        text
                    }
                    project_description {
                        text
                    }
                    project_link {
                        url
                        type
                    }
                }
                seeMoreText {
                    text
                }
                seeMoreLink {
                    url
                }
                featured_projects_title {
                    text
                }
                featuredProjectsSubtitle {
                    text
                }
                testimonialsTitle {
                    text
                }
                testimonials {
                    customerName {
                        text
                    }
                    quote {
                        text
                    }
                }
                videos {
                    caption {
                        html
                        text
                    }
                    videoId {
                        text
                    }
                }
                content_block_1 {
                    html
                }
                content_block_2 {
                    html
                }
            }
        }
    }
`;
