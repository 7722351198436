import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import {
    Layout,
    StaticValueCallouts,
    Hero,
    AltHeroText,
    FeaturedProjects,
} from '../components/common';

import InfoBar from '../components/project/InfoBar';
import RichContentSection from '../components/common/RichContentSection';
import { SectionHighlight } from '../components/project';
import { parseInfoBarItems, parseSimilarProjects } from '../util/parse';
import { withPreviewData } from '../util/preview';
import PrismicImage from '../models/prismicImage';

const Project = ({ data: staticData, location }) => {
    const data = withPreviewData(staticData);

    const {
        title,
        description,
        json_ld: jsonLD,
        infoBarItems,
        mainImage,
        projectTitle,
        date,
        similarProjectTitle,
        similarProjects,
        content_block_1: contentBlock1,
        ...otherData
    } = data.prismicProject.data;

    const parsedSimilarProjects = parseSimilarProjects(similarProjects);

    const hasContentBlock1 = (contentBlock1 || {}).text;
    const hasSimilarProjects = !!parsedSimilarProjects.length;

    return (
        <Layout
            location={location}
            customMetaData={{
                title: title.text,
                description: description.text,
                jsonLD: (jsonLD || {}).text,
            }}
        >
            <Hero image={new PrismicImage(mainImage)}>
                <AltHeroText title={projectTitle.text} subtitle={date.text} />
            </Hero>
            <InfoBar items={parseInfoBarItems(infoBarItems)} />
            <SectionHighlight data={otherData} />
            {hasContentBlock1 && (
                <RichContentSection html={contentBlock1.html} />
            )}
            {hasSimilarProjects && (
                <FeaturedProjects
                    title={similarProjectTitle.text}
                    projects={parsedSimilarProjects}
                />
            )}
            <StaticValueCallouts />
        </Layout>
    );
};

export default Project;

Project.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export const query = graphql`
    query ProjectQuery($uid: String!) {
        prismicProject(uid: { eq: $uid }) {
            data {
                title {
                    text
                }
                description {
                    text
                }
                json_ld {
                    text
                }
                introDescription {
                    html
                }
                quote {
                    text
                }
                date {
                    text
                }
                mainImage {
                    ...ProPrismicImageMetadata_NoBase64
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                projectTitle {
                    text
                }
                imageRow {
                    image {
                        ...ProPrismicImageMetadata
                    }
                }
                credits {
                    text
                }
                postDescription {
                    html
                }
                imageGrid {
                    isFeatured
                    image {
                        ...ProPrismicImageMetadata
                    }
                }
                infoBarItems {
                    body {
                        text
                    }
                    title {
                        text
                    }
                }
                similarProjectTitle {
                    text
                }
                similarProjects {
                    image {
                        ...ProPrismicImageMetadata
                    }
                    link {
                        url
                    }
                    title {
                        text
                    }
                }
                content_block_1 {
                    html
                }
                kuula_3d_render_description {
                    html
                }
                kuula_3d_render_id
            }
        }
    }
`;
