import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Caption, Section } from '../common';
import Lightbox from '../common/Lightbox';
import openQuoteIcon from '../../images/quote_open.svg';
import { track } from '../../services/segment';
import { parseImageGridData, parseImageRowData } from '../../util';
import ImageGrid from './ImageGrid';
import ImageRow from './ImageRow';
import Kuula3dRender from './Kuula3dRender';

const useStyles = makeStyles((theme) => ({
    imageRow: {
        margin: theme.spacing(2, 0),
    },
    imageGrid: {
        margin: theme.spacing(2, 0),
    },
    quoteIcon: {
        margin: theme.spacing(4, 0),
        width: '100%',
        textAlign: 'left',
        marginLeft: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    caption: {
        '& p': {
            margin: '1em 0',
        },
    },
    quote: {
        '& p': {
            margin: '1em 0',
        },
        fontSize: 24,
    },
}));

const SectionHighlight = ({
    data: {
        introDescription,
        imageRow,
        kuula_3d_render_id: kuula3dRenderId,
        kuula_3d_render_description: kuula3dRenderDescription,
        postDescription,
        imageGrid,
        credits,
        quote,
    },
}) => {
    const classes = useStyles();
    const gridImages = parseImageGridData(imageGrid);
    const rowImages = parseImageRowData(imageRow);
    const lightboxImages = gridImages
        .concat(rowImages)
        .map(({ image }) => image);

    const hasQuote = !!(quote || {}).text;
    const hasIntroDescription = !!(introDescription || {}).html;
    const hasPostDescription = !!(postDescription || {}).html;
    const hasGridImages = !!gridImages.length;
    const hasRowImages = !!rowImages.length;
    const hasCredits = !!(credits || {}).text;

    const [open, setOpen] = useState(false);
    const [startIndex, setStartIndex] = useState(0);

    const shouldRenderFirstSection =
        hasQuote || hasIntroDescription || hasGridImages;

    const shouldRenderLastSection =
        hasPostDescription || hasRowImages || hasCredits;

    return (
        <>
            {shouldRenderFirstSection && (
                <Section>
                    {hasQuote ? (
                        <Grid container spacing={8} justify="space-between">
                            <Grid item md={7}>
                                <Caption
                                    classes={{ root: classes.caption }}
                                    caption={introDescription.html}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <div className={classes.quoteIcon}>
                                    <img src={openQuoteIcon} alt="quote icon" />
                                </div>
                                <Caption
                                    classes={{ root: classes.quote }}
                                    caption={quote.text}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Caption
                            classes={{ root: classes.caption }}
                            caption={introDescription.html}
                        />
                    )}
                    <ImageGrid
                        classes={{ root: classes.imageGrid }}
                        images={gridImages}
                        onClick={handleGridImageClick}
                    />
                </Section>
            )}
            {!!kuula3dRenderId && (
                <Kuula3dRender
                    id={kuula3dRenderId}
                    description={kuula3dRenderDescription}
                />
            )}
            {shouldRenderLastSection && (
                <Section>
                    {postDescription.html ? (
                        <Caption
                            classes={{ root: classes.caption }}
                            caption={postDescription.html}
                        />
                    ) : undefined}
                    <ImageRow
                        classes={{ root: classes.imageRow }}
                        images={rowImages}
                        onClick={handleRowImageClick}
                        credits={credits.text}
                    />
                </Section>
            )}
            <Lightbox
                open={open}
                setOpen={setOpen}
                images={lightboxImages}
                startIndex={startIndex}
            />
        </>
    );

    function openLightbox(index) {
        setOpen(true);
        setStartIndex(index);

        try {
            track('Project image clicked', {
                url: lightboxImages[index].url,
            });
        } catch (err) {
            console.error(err);
        }
    }

    function handleGridImageClick(index) {
        openLightbox(index);
    }

    function handleRowImageClick(index) {
        openLightbox(gridImages.length + index);
    }
};

SectionHighlight.propTypes = {
    data: PropTypes.object.isRequired,
};

export default SectionHighlight;
