import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { parseLocalePins } from '../../../util/parse';
import { withPreviewData } from '../../../util/preview';

import Locales from './Locales';

const query = graphql`
    query LocalesQuery {
        prismicLocales {
            data {
                title {
                    text
                }
                text {
                    html
                }
                pins {
                    city_name {
                        text
                    }
                    latitude
                    longitude
                }
            }
        }
    }
`;

function StaticLocales() {
    return (
        <StaticQuery
            query={query}
            render={(staticData) => {
                const {
                    prismicLocales: {
                        data: { title, text, pins },
                    },
                } = withPreviewData(staticData);

                return (
                    <Locales
                        title={title.text}
                        text={text.html}
                        pins={parseLocalePins(pins)}
                    />
                );
            }}
        />
    );
}

export default StaticLocales;
