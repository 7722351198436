import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FluidImage } from '../common/Image';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(8, 0, 0),
    },
    credits: {
        marginTop: theme.spacing(4),
        color: theme.palette.grey[700],
        textAlign: 'right',
        fontStyle: 'italic',
    },
    item: {
        overflow: 'hidden',
    },
    image: {
        paddingTop: '75%',
        [theme.breakpoints.up('md')]: {
            maxHeight: 300,
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 200,
        },
    },
}));

function ImageRow(props) {
    const { images, credits, onClick, ...otherProps } = props;
    const classes = useStyles({ classes: otherProps.classes });

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-start"
                spacing={4}
            >
                {images.map((item, i) => (
                    <Grid key={`image-item-${i + 1}`} item xs={6} sm={4}>
                        <div
                            className={classes.item}
                            role="button"
                            tabIndex={i}
                            onKeyDown={(e) => e.key === 'Enter' && onClick(i)}
                            onClick={() => onClick(i)}
                        >
                            <FluidImage
                                className={classes.image}
                                image={item.image}
                                maxWidth={400}
                            />
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Typography variant="body1" className={classes.credits}>
                {credits}
            </Typography>
        </div>
    );
}

ImageRow.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object.isRequired,
        })
    ).isRequired,
    credits: PropTypes.string,
    onClick: PropTypes.func,
};

ImageRow.defaultProps = {
    credits: '',
    onClick: () => {},
};

export default ImageRow;
